/* eslint-disable react-hooks/exhaustive-deps */
import '../../../App.css';
import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import {Modal as ModalAntd} from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    FileAddOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {startOfWeek, addDays, format} from 'date-fns';
import ResourceTracker from './ResourceTracker/ResourceTracker';
import ShiftTabs from './Tabs/Tabs';
import Header from '../components/Header/Header';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/free-solid-svg-icons';
import AddShift from './Modal/AddShift/AddShift';
import {
  addNewShift,
  deleteJob,
  duplicateShift,
  editJobGridLocation,
  getWeeklyJobs,
} from "../../../api/apiCall";
import {useAuth} from '../../../auth/auth';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSignalRConnection from '../../../Service/SignalRService';

Modal.setAppElement('#root');

type GridDataType = {
    [key: string]: any;
};

type Day = {
    date: string
    dayOfWeek: string
}

const Home = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [days, setDays] = useState<Day[]>([]);
    const [gridData, setGridData] = useState<GridDataType>({});
    const [modalIsOpen, setIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false);
    const [currentCell, setCurrentCell] = useState<any>({});
    const [dragStatus, setDragStatus] = useState<any>({});
    const [flippedCells, setFlippedCells] = useState<any>({});
    const [value, setValue] = useState(0);
    const [date, setDate] = useState(null);
    const [week, setWeek] = useState<any>(searchParams.get('week') || 1);
    const [year, setYear] = useState<any>(searchParams.get('year') || 2024);
    const [dateOfTheShift, setDateOfTheShift] = useState(null)
    const [formDate, setFormDate] = useState(null)
    const {state} = useAuth();
    const [length, setLength] = useState(20);
    const [id, setId] = useState('')
    const [shiftData, setShiftData] = useState<any>();
    const [duplicateMode, setDuplicateMode] = useState<any>(false);
    const [duplicatedShift, setDuplicatedShift] = useState<any>(null);
    const [disabledCells, setDisabledCells] = useState<any>([]);
    const {connection, isConnected} = useSignalRConnection();
    const [loading, setLoading] = useState(false);
    const [groupName, setGroupName] = useState(
        `${searchParams.get("week") ? searchParams.get("week") : week}-${
          searchParams.get("year") ? searchParams.get("year") : year}`
    );

    document.title= `Resource - Week ${searchParams.get('week')}`

    const addToGroup = async () => {
        try {
            if(connection) {
                await connection.invoke('JoinGroup', `${week}-${year}`)
                await connection.invoke('TestGroup', `${week}-${year}`)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        addToGroup();
        getAllWeeklyShifts();
    }, [connection, date, isConnected])

    //Set the duplicate mode and duplicate shift
    const handleDuplicateMode = (colIndex: any, rowIndex: any) => {
        const newShift = gridData[`${colIndex}-${rowIndex}`];
        if(newShift) {
            setDuplicatedShift(newShift);
            setDuplicateMode(true);
        }
    };

    const handleDuplicate = async (colIndex: any, rowIndex: any, day: any) => {
        if(gridData[`${colIndex}-${rowIndex}`]) {
            const deleteShift = await deleteJob(state.user?.token, gridData[`${colIndex}-${rowIndex}`].id);

            if(deleteShift) {
                gridData[`${colIndex}-${rowIndex}`] = duplicatedShift;
                const response = await duplicateShift(
                    state.user?.token, duplicatedShift.id, day, rowIndex, colIndex, week, `${week}-${year}`
                )
                if(response) {
                    getAllWeeklyShifts();
                    setDuplicateModalIsOpen(false)
                } else{
                    toast.info('Something went wrong!')
                }
            }
        } else {
            gridData[`${colIndex}-${rowIndex}`] = duplicatedShift;
            const response = await duplicateShift(
                state.user?.token, duplicatedShift.id, day, rowIndex, colIndex, week, `${week}-${year}`
            )
            if(response) {
                getAllWeeklyShifts();
                setDuplicateModalIsOpen(false)
            } else{
                toast.info('Something went wrong!')
            }
        }
        // if we want to duplicate just once
        // if (duplicateMode && duplicatedShift) {
        //     setDuplicateMode(false);
        //     setDuplicatedShift(null);
        // }
    };

    const removeObject = (array: any, rowIndex: any, colIndex: any) => {
        return array.filter((item: any) => !(item.rowIndex === rowIndex && item.colIndex === colIndex));
    };

    const addDisabledCell = (colIndex: any, rowIndex: any) => {
        if(gridData[`${colIndex}-${rowIndex}`]){return}
        setDisabledCells((prevCoordinates: any) => {
            const cellExists = prevCoordinates.some((cell: any) => cell.colIndex === colIndex && cell.rowIndex === rowIndex);
            if (!cellExists) {
                return [
                    ...prevCoordinates,
                    { colIndex, rowIndex },
                ];
            }
            return prevCoordinates;
        });
    };

    const removeDisabledCell = (colIndex: any, rowIndex: any) => {
        setDisabledCells((prevData: any) => removeObject(prevData, rowIndex, colIndex));
    }

    const updateParams = () => {
        setSearchParams({ year, week });
    };
   
    const openModal = (colIndex: any, rowIndex: any, date: any, day: any) => {
        if(colIndex === 7) {
            return;
        }
        if(duplicateMode) {
            if(gridData[`${colIndex}-${rowIndex}`]) {
                setCurrentCell({ colIndex, rowIndex });
                setFormDate(day)
                setDuplicateModalIsOpen(true)
                return;
            }
            handleDuplicate(colIndex, rowIndex, day);
            return;
        }
        if(gridData[`${colIndex}-${rowIndex}`]) {
            openEditModal(colIndex, rowIndex);
            return;
        }

        const isCellDisabled = disabledCells.some((cells: any) => 
            colIndex === cells.colIndex && rowIndex === cells.rowIndex
        );

        connection.invoke('DisableCell', `${week}-${year}`, rowIndex, colIndex)

        if (isCellDisabled) {
            return;
        }

        setCurrentCell({ colIndex, rowIndex });
        setIsOpen(true);
        setDateOfTheShift(date)
        setFormDate(day)
    };

    const getAllWeeklyShifts = async () => {
        try {
            const response = await getWeeklyJobs(state.user?.token, week, year);

            const rowIndexes: [] = response.map(({ job }: any) => job.rowIndex);
            const maxRowIndex = Math.max(...rowIndexes);

            if(maxRowIndex < 20) {
                setLength(20)
            }else {
                setLength(maxRowIndex + 2)
            }

            const newGridData: any = {};
            response.forEach(({ job, workers }:any) => {
                const colIndex = job.columnIndex;
                const rowIndex = job.rowIndex;
                newGridData[`${colIndex}-${rowIndex}`] = {
                    id: job.id,
                    project: job.project.projectName,
                    wp: job.whatsAppName,
                    region: job.region,
                    date: job.jobDate,
                    location: job.location,
                    postCode: job.postCode,
                    colIndex: job.columnIndex,
                    rowIndex : job.rowIndex,
                    description: job.jobDescription,
                    shiftType: job.shiftType,
                    requiredWorkers: job.requiredWorkers,
                    client: job.project.company.companyName,
                    shiftStatus: job.jobStatus,
                    startTime: job.startTime.slice(11,16),
                    endTime: job.endTime.slice(11,16),
                    hours: job.hours,
                    notes: job.notes,
                    notesJson: job.notesJson,
                    jobNumber: job.jobNumber,
                    labourOrderNR: job.labourOrderNR,
                    invoice: job.invoice,
                    plants_Materials: job.plants_Materials,
                    onCallNumber: job.onCallNumber,
                    clientSiteContacts: job.clientSiteContact,
                    vehicleRegistration: job.vehicleRegistration,
                    additionalInformation: job.additionalInformation,
                    hotelStatus: job.hotelStatus,
                    workers: workers && workers.length > 0 ? workers : []
                };
            });
            setGridData(newGridData);
            updateParams()
        } catch (error) {
            console.error("Error fetching weekly shifts: ", error);
        }
    }

    const openEditModal = (colIndex: number, rowIndex: number, value = 0) => {
        setCurrentCell({ colIndex, rowIndex });
        setId(gridData[`${colIndex}-${rowIndex}`].id)
        setEditModalIsOpen(true);
        setValue(value)
    };

    const getWeekStartingFromSaturday = (date: Date) => {
        const saturday = startOfWeek(date, { weekStartsOn: 6 });
        return Array.from({ length: 8 }).map((_, i) => {
            const day = addDays(saturday, i);
            return {
                day: format(day, 'yyyy-MM-dd'),
                date: format(day, 'dd/MM/yyyy'),
                dayOfWeek: format(day, 'EEEE')
            };
        });
    };

    const items = [
        {
            label: 'Edit',
            key: '1',
            icon: <EditOutlined />,
        },
        {
            label: 'Workers',
            key: '2',
            icon: <UserOutlined/>
        },
        {
            label: 'Duplicate',
            key: '3',
            icon: <FileAddOutlined />,
        },
        {
            label: 'Delete',
            key: '4',
            icon: <DeleteOutlined />,
            danger: true,
        },
    ];

    const handleRightClick = (
      colIndex: number,
      rowIndex: number,
      event: { preventDefault: () => void }
    ) => {
      event.preventDefault();
      setCurrentCell({ colIndex, rowIndex });
    };

    const handleDelete = async (colIndex: number, rowIndex: number) => {
        const response = await deleteJob(state.user?.token, gridData[`${colIndex}-${rowIndex}`].id);
        if(response?.data === 'Deleted!'){
            toast.error('Shift deleted successfully')
            connection.invoke('RemoveJobFromGrid', `${week}-${year}`, gridData[`${colIndex}-${rowIndex}`].id)
        }else {
            toast.error('Something went wrong!')
        }
        getAllWeeklyShifts();

        // handle flipped cells when shift deleted
        setFlippedCells((prevData: any) => (
            {
                ...prevData,
                [`${colIndex}-${rowIndex}`]: false,
            }
        ));
        setDeleteModalIsOpen(false)
    };

    const handleMenuClick = ({ key }: any) => {
        switch (key) {
            case '1':
                openEditModal(currentCell.colIndex, currentCell.rowIndex);
                break;
            case '2':
                openEditModal(currentCell.colIndex, currentCell.rowIndex, 1);
                break;
            case '3':
                handleDuplicateMode(currentCell.colIndex, currentCell.rowIndex)
                break;
            case '4':
                setDeleteModalIsOpen(true)
                break;
            default:
                break;
        }
    };

    const closeModal = () => {
        connection.invoke('EnableCell', `${week}-${year}`, currentCell.rowIndex, currentCell.colIndex);
        setIsOpen(false);
    }

    const closeEditModal = () => {
        setEditModalIsOpen(false);
        setShiftData(null);
        getAllWeeklyShifts();
    };

    const handleSubmit = async (data: any) => {
        setLoading(true)
        data.startTime = format(new Date(`${data.jobDate}T${data.startTime}:00`), "yyyy-MM-dd'T'HH:mm:ss");
        data.endTime = format(new Date(`${data.jobDate}T${data.endTime}:00`), "yyyy-MM-dd'T'HH:mm:ss");
        data.HubGroupName = `${week}-${year}`;
        const response = await addNewShift(state.user?.token, data);
        if(response){
            setLoading(false)
        }
        // getAllWeeklyShifts();
        closeModal();
        toast.success('Shift added successfully')
    };

    const handleDragStart = (colIndex: number, rowIndex: number, event: any) => {
        event.dataTransfer.setData('text/plain', JSON.stringify({ colIndex, rowIndex }));
    };

    const handleDrop = async (colIndex: number, rowIndex: number, event: any, day: any) => {
        event.preventDefault();
        day = format(new Date(`${day}T00:00:00`), "yyyy-MM-dd'T'HH:mm:ss");
        try {
            if(colIndex === 7) {
                return
            }
            const data = JSON.parse(event.dataTransfer.getData('text/plain'));
            const draggedContent = gridData[`${data.colIndex}-${data.rowIndex}`];
            // if there is data on the cell return
            if(gridData[`${colIndex}-${rowIndex}`]) {
                // if they are same cell return
                if(`${colIndex}-${rowIndex}` === `${data.colIndex}-${data.rowIndex}`) {
                    return;
                }
                setDragStatus((prev: any) => ({ ...prev, [`${colIndex}-${rowIndex}`]: 'failed' }));

                setTimeout(() => {
                    setDragStatus((prev: any) => ({ ...prev, [`${colIndex}-${rowIndex}`]: '' }));
                }, 1000);

                toast.error('You cannot put a shift there')
                return;
            }
            //if the cell is disabled return
            const isCellDisabled = disabledCells.some((cells: any) => 
                colIndex === cells.colIndex && rowIndex === cells.rowIndex
            );
            if (isCellDisabled) {
                return;
            }

            const response = await editJobGridLocation(state.user?.token, {
                jobId: gridData[`${data.colIndex}-${data.rowIndex}`].id,
                oldRowIndex: data.rowIndex,
                oldColumnIndex: data.colIndex,
                newRowIndex: rowIndex,
                newColumnIndex: colIndex,
                jobDate: day
            })
            if(response) {
                connection.invoke('RelocateJobGridLocation',`${week}-${year}`,gridData[`${data.colIndex}-${data.rowIndex}`].id, rowIndex, colIndex)
            }

            setGridData(prevData => {
                const newData = { ...prevData };
                delete newData[`${data.colIndex}-${data.rowIndex}`];
                newData[`${colIndex}-${rowIndex}`] = draggedContent;
                setDragStatus((prev: any) => ({ ...prev, [`${colIndex}-${rowIndex}`]: 'success' }));

                setTimeout(() => {
                    setDragStatus((prev: any) => ({ ...prev, [`${colIndex}-${rowIndex}`]: '' }));
                }, 1000);

                return newData;
            });

            setFlippedCells((prevData: any) => (
                {
                    ...prevData,
                    [`${data.colIndex}-${data.rowIndex}`]: false,
                }
            ));
        } catch(error) {
            console.log(error)
        }
    };

    const handleDragOver = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
    };

    const handleFlip = (colIndex: number, rowIndex: number) => {
        setFlippedCells((prevData: any) => (
            {
                ...prevData,
                [`${colIndex}-${rowIndex}`]: !prevData[`${colIndex}-${rowIndex}`],
            }
        ));
    };

    useEffect(() => {
        if(date) {
            const weekFromSaturday = getWeekStartingFromSaturday(date);
            setDays(weekFromSaturday);
        }
        getAllWeeklyShifts()
        setFlippedCells({})
    }, [date]);

    useEffect(() => {
        if(connection) {
            if(searchParams.get('week') === week && searchParams.get('year') === year){
                return;
            }
            connection.invoke('LeaveGroup', groupName)
            setGroupName(`${week}-${year}`)
        }
    },[date])

    if (connection) {
        connection.on('TestGroup', (group: any) => {
            console.log('group returned', group);
        });

        connection.on('DisableCell', (rowIndex: any, columnIndex: any) => {
            addDisabledCell(columnIndex, rowIndex);
        });

        connection.on('EnableCell', (rowIndex: any, columnIndex: any) => {
            removeDisabledCell(columnIndex, rowIndex);
        });

        connection.on('GroupLeftConfirmation', (group: any) => {
            console.log('group left', group);
        });
    }

    if (connection) {
        connection.on('LoadNewJob', (returnModel: any) => {
            const newJob = {
                id: returnModel.job.id,
                project: returnModel.job.project.projectName,
                wp: returnModel.job.whatsAppName,
                date: returnModel.job.jobDate,
                location: returnModel.job.location,
                postCode: returnModel.job.postCode,
                colIndex: returnModel.job.columnIndex,
                rowIndex: returnModel.job.rowIndex,
                client: returnModel.job.project.company.companyName,
                description: returnModel.job.jobDescription,
                shiftType: returnModel.job.shiftType,
                requiredWorkers: returnModel.job.requiredWorkers,
                shiftStatus: returnModel.job.jobStatus,
                hours: returnModel.job.hours,
                notes: returnModel.job.notes,
                startTime: returnModel.job.startTime.slice(11, 16),
                endTime: returnModel.job.endTime.slice(11, 16),
                notesJson: returnModel.job.notesJson,
                jobNumber: returnModel.job.jobNumber,
                labourOrderNR: returnModel.job.labourOrderNR,
                invoice: returnModel.job.invoice,
                plants_Materials: returnModel.job.plants_Materials,
                onCallNumber: returnModel.job.onCallNumber,
                clientSiteContacts: returnModel.job.clientSiteContact,
                vehicleRegistration: returnModel.job.vehicleRegistration,
                additionalInformation: returnModel.job.additionalInformation,
                workers: returnModel.workers && returnModel.workers.length > 0 ? returnModel.workers : []
            };

            setGridData((prevData: any) => ({
                ...prevData,
                [`${returnModel.job.columnIndex}-${returnModel.job.rowIndex}`]: newJob
            }));
        });

        connection.on('RelocateJobGridLocation', (jobId: string | number, newRowIndex: number, newColumnIndex: number) => {
            setGridData((prevData) => {
                const updatedGridData = { ...prevData };

                for (const [key, shift] of Object.entries(updatedGridData)) {
                    if (shift.id === jobId) {
                        delete updatedGridData[key];

                        updatedGridData[`${newColumnIndex}-${newRowIndex}`] = {
                            ...shift,
                            colIndex: newColumnIndex,
                            rowIndex: newRowIndex
                        };
                        break;
                    }
                }
                return updatedGridData;
            });
        });

        connection.on('RemoveJobFromGrid', (jobId: string | number) => {
            setGridData((prevData) => {
                const updatedGridData = { ...prevData };
                for (const [key, shift] of Object.entries(updatedGridData)) {
                    if (shift.id === jobId) {
                        delete updatedGridData[key];
                    }
                }
                return updatedGridData;
            });
        });

        connection.on('EditJob', (jobModel: any) => {
            const newJob = {
                id: jobModel.job.id,
                wp: jobModel.job.whatsAppName,
                project: jobModel.job.project.projectName,
                date: jobModel.job.jobDate,
                location: jobModel.job.location,
                postCode: jobModel.job.postCode,
                colIndex: jobModel.job.columnIndex,
                rowIndex: jobModel.job.rowIndex,
                description: jobModel.job.jobDescription,
                shiftType: jobModel.job.shiftType,
                requiredWorkers: jobModel.job.requiredWorkers,
                shiftStatus: jobModel.job.jobStatus,
                hours: jobModel.job.hours,
                notes: jobModel.job.notes,
                client: jobModel.job.project.company.companyName,
                startTime: jobModel.job.startTime.slice(11, 16),
                endTime: jobModel.job.endTime.slice(11, 16),
                notesJson: jobModel.job.notesJson,
                jobNumber: jobModel.job.jobNumber,
                labourOrderNR: jobModel.job.labourOrderNR,
                invoice: jobModel.job.invoice,
                plants_Materials: jobModel.job.plants_Materials,
                onCallNumber: jobModel.job.onCallNumber,
                clientSiteContacts: jobModel.job.clientSiteContact,
                vehicleRegistration: jobModel.job.vehicleRegistration,
                additionalInformation: jobModel.job.additionalInformation,
                workers: jobModel.workers && jobModel.workers.length > 0 ? jobModel.workers : []
            };

            setGridData((prevData) => {
                const updatedGridData = { ...prevData };
                for (const [key, shift] of Object.entries(updatedGridData)) {
                    if (shift.id === jobModel.job.id) {
                        updatedGridData[key] = newJob;
                    }
                }
                return updatedGridData;
            });
        });

        connection.on('EditJobNotesJson', (jobID: any, notesJson: any)=> {
            setGridData((prevData)=>{
                const updatedGridData = { ...prevData };
                for (const [key, shift] of Object.entries(updatedGridData)) {
                    if (shift.id === jobID) {
                        updatedGridData[key] = {
                            ...shift,
                            notesJson: notesJson
                        }
                    }
                }
                return updatedGridData;
            })
        })

        connection.on('DuplicateJob', (returnModel: any) => {
            const newJob = {
                id: returnModel.job.id,
                wp: returnModel.job.whatsAppName,
                project: returnModel.job.project.projectName,
                date: returnModel.job.jobDate,
                location: returnModel.job.location,
                postCode: returnModel.job.postCode,
                colIndex: returnModel.job.columnIndex,
                rowIndex: returnModel.job.rowIndex,
                client: returnModel.job.project.company.companyName,
                description: returnModel.job.jobDescription,
                shiftType: returnModel.job.shiftType,
                requiredWorkers: returnModel.job.requiredWorkers,
                shiftStatus: returnModel.job.jobStatus,
                hours: returnModel.job.hours,
                notes: returnModel.job.notes,
                startTime: returnModel.job.startTime.slice(11, 16),
                endTime: returnModel.job.endTime.slice(11, 16),
                notesJson: returnModel.job.notesJson,
                jobNumber: returnModel.job.jobNumber,
                labourOrderNR: returnModel.job.labourOrderNR,
                invoice: returnModel.job.invoice,
                plants_Materials: returnModel.job.plants_Materials,
                onCallNumber: returnModel.job.onCallNumber,
                clientSiteContacts: returnModel.job.clientSiteContact,
                vehicleRegistration: returnModel.job.vehicleRegistration,
                additionalInformation: returnModel.job.additionalInformation,
                workers: returnModel.workers && returnModel.workers.length > 0 ? returnModel.workers : []
            };
  
            setGridData((prevData: any) => ({
                ...prevData,
                [`${returnModel.job.columnIndex}-${returnModel.job.rowIndex}`]: newJob
            }));
        });
    }

    useEffect(() => {
        const refreshDuplicateMode = (event: any) => {
            if (event.key === 'Escape') {
                setDuplicateMode(false)
            }
        };
        window.addEventListener('keydown', refreshDuplicateMode);
        return () => {
            window.removeEventListener('keydown', refreshDuplicateMode);
        };
    }, []);

    return (
        <>
            <Header
                setDate={setDate}
                week={week}
                setWeek={setWeek}
                year={year}
                setYear={setYear}
            />
            <ResourceTracker
                days={days}
                gridData={gridData}
                setGridData={setGridData}
                handleCellClick={openModal}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                handleDragOver={handleDragOver}
                items={items}
                handleMenuClick={handleMenuClick}
                handleRightClick={handleRightClick}
                dragStatus={dragStatus}
                handleFlip={handleFlip}
                flippedCells={flippedCells}
                length={length}
                setLength={setLength}
                duplicateMode={duplicateMode}
                disabledCells={disabledCells}
                connection={connection}
                isConnected={isConnected}
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Enter Cell Data"
                className='add-modal'
                style={{
                    overlay: { zIndex: 2 }
                }}
            > 
                <h2 className='add-shift'>Add a New Shift</h2>
                <AddShift
                    week={week}
                    onSubmit={handleSubmit}
                    closeModal={closeModal}
                    dateOfTheShift={dateOfTheShift}
                    currentCell={currentCell}
                    formDate={formDate}
                    loading={loading}
                />
            </Modal>
            <Modal
                isOpen={editModalIsOpen}
                onRequestClose={closeEditModal}
                contentLabel="Enter Cell Data"
                className='edit-modal'
                style={{
                    overlay: { zIndex: 2 }
                }}
            >
                <div className='close-modal' onClick={closeEditModal}>
                    <FontAwesomeIcon icon={faX} size={'lg'} />
                </div>
                <ShiftTabs
                    id={id}
                    week={week}
                    year={year}
                    dateOfTheShift={dateOfTheShift}
                    currentCell={currentCell}
                    closeEditModal={closeEditModal}
                    value={value}
                    setValue={setValue}
                    shiftData={shiftData}
                    setShiftData={setShiftData}
                    connection={connection}
                />
            </Modal>
            <ModalAntd
                title={`Are you sure you want to delete "${gridData[`${currentCell.colIndex}-${currentCell.rowIndex}`]?.wp}"?`}
                centered
                open={deleteModalIsOpen}
                okButtonProps={{danger: true}}
                cancelText={'NO'}
                okText={'YES'}
                onOk={() => handleDelete(currentCell.colIndex, currentCell.rowIndex)}
                onCancel={() => setDeleteModalIsOpen(false)}
            />
            <ModalAntd
                title={`Are you sure you want to overwrite cell?`}
                centered
                open={duplicateModalIsOpen}
                okButtonProps={{danger: true}}
                cancelText={'NO'}
                okText={'YES'}
                onOk={() => handleDuplicate(currentCell.colIndex, currentCell.rowIndex, formDate)}
                onCancel={() => setDuplicateModalIsOpen(false)}
            />
            {duplicateMode? (
                <div className="bottom-left">Press Esc to disable Duplicate Mode</div>
            ) : (null)}
        </>
    );
};

export default Home;
