import React, { useEffect, useState } from "react";
import { TileLayer, FeatureGroup, Polygon, Circle, Marker, Tooltip, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { MapContainer } from "react-leaflet";
import { useAuth } from "../../../auth/auth";
import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import L from "leaflet";
import { addGeofence, deleteGeofence, getGeofences, updateGeofence } from "../../../api/apiCall";
import { Button } from "antd";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import GeofenceModal from "./Modal/GeofenceModal";
import { toast } from "react-toastify";
import './LiveTracker.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import "leaflet/dist/leaflet.css";

const MapController = ({ coordinates, zoom }: { coordinates: [number, number], zoom: number }) => {
    const map = useMap();
    useEffect(() => {
        if (coordinates) {
            map.setView(coordinates, zoom);
        }
    }, [coordinates, map]);
    return null;
};

const customDivIcon = L.divIcon({
    className: 'pulse',
    html: '<span class="pulse"></span>',
    iconSize: [20, 20]
});

const LiveTracker = () => {
    const {state} = useAuth();
    const [coordinates, setCoordinates] = useState<any>([51.505, -0.09]);
    const [modalOpen, setModalOpen] = useState(false);
    const [geofenceName, setGeofenceName] = useState("");
    const [geofenceColor, setGeofenceColor] = useState("black");
    const [selectedGeofence, setSelectedGeofence] = useState<any>(null);
    const [geofences, setGeofences] = useState<any>();
    const [createdLayer, setCreatedLayer] = useState<any>(null);
    const [workers, setWorkers] = useState<any>([]);
    const [zoom, setZoom] = useState<number>(9);
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        setOpen(false);
    };

    const getWorkerCoordinates = (latitude: any, longitude: any) => {
        setCoordinates([latitude, longitude]);
        setZoom(13);
    }

    const getGeoFences = async () => {
        try {
            const response = await getGeofences(state.user?.token);
            setGeofences(response?.data || []);
        } catch (error) {
            console.error("Error fetching geofences", error);
        }
    };

    const handleSaveGeofence = async () => {
        try {
            if (createdLayer) {
                const geofenceData = formatGeofenceData(createdLayer);
                const response = await addGeofence(state.user?.token, geofenceData);
                if(response) {
                    toast.success('Geofence added successfully');
                }
                getGeoFences();
                setCreatedLayer(null);
            } else if (selectedGeofence) {
                const updatedGeofence = {
                    ...selectedGeofence,
                    identifier: geofenceName,
                    color: geofenceColor,
                };
                const response = await updateGeofence(state.user?.token, updatedGeofence);
                if(response) {
                    toast.success('Geofence updated successfully');
                }
                getGeoFences();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setModalOpen(false);
        }
    };

    const formatGeofenceData = (layer: any) => ({
        type: layer.layerType,
        identifier: geofenceName,
        color: geofenceColor,
        radius: layer.layerType === "circle" ? layer.layer.getRadius() > 200 ? layer.layer.getRadius() : 200  : 0,
        latitude: layer.layerType === "circle" ? layer.layer.getLatLng().lat : 0,
        longitude: layer.layerType === "circle" ? layer.layer.getLatLng().lng : 0,
        vertices:
          layer.layerType === "polygon"
            ? layer.layer.getLatLngs()[0].map((latLng: any) => [latLng.lat, latLng.lng])
            : [],
    });

    const handleGeofenceClick = (geofence: any) => {
        setSelectedGeofence(geofence);
        setGeofenceName(geofence.identifier);
        setGeofenceColor(geofence.color);
        setModalOpen(true);
    };

    const handleGeofenceDelete = async () => {
        try {
            if (selectedGeofence) {
                const response = await deleteGeofence(state.user?.token, selectedGeofence.id);
                if(response) {
                    getGeoFences();
                    toast.error('Geofence deleted successfully')
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setModalOpen(false);
        }
    };

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('https://api.pace-is.co.uk/GeoLocationHub', {
              accessTokenFactory: () => (state.user?.token ? state.user.token : ''),
            })
            .withAutomaticReconnect([10000, 30000])
            .configureLogging(LogLevel.Information)
            .build();

        const startConnection = async () => {
            try {
                await newConnection.start();
                newConnection.invoke('JoinAdministrationGroup');
                console.log("SignalR Connected")
            } catch (err) {
                console.log("Error connecting to SignalR", err);
                setTimeout(startConnection, 2000);
            }
        };

        newConnection.onclose(async (error) => {
            console.error("Connection closed unexpectedly", error);
            if (error) {
                console.log("Attempting to reconnect...");
                setTimeout(startConnection, 2000);
            }
        });

        newConnection.onreconnecting((error) => {
            console.warn("Connection lost. Reconnecting...", error);
        });

        newConnection.onreconnected((connectionId) => {
            console.log("SignalR reconnected. Connection ID:", connectionId);
        });

        newConnection.serverTimeoutInMilliseconds = 60000;

        newConnection.on('WorkerInGeofence', (model: any, text: any) => {
            console.log(model, text, 'texti');
        });

        startConnection();

        newConnection.on('LoadWorkerToMap', (newWorker: any) => {
            setWorkers((prevWorkers: any[]) => {
                const isWorkerExist = prevWorkers.some(worker => worker.id === newWorker.id);

                if (isWorkerExist) {
                  return prevWorkers.map(worker => 
                    worker.id === newWorker.id 
                    ? { 
                        ...worker, 
                        longitude: newWorker.longitude,
                        latitude: newWorker.latitude,
                        lastUpdate: Date.now(),
                      }
                    : worker
                  );
                }

                return [...prevWorkers, newWorker];
            });
        });

        const removeWorker = setInterval(() => {
            setWorkers((prevWorkers: any) =>
                prevWorkers.filter((worker: any) => Date.now() - worker.lastUpdate < 10000)
            );
        }, 10000);

        return () => {
            if (newConnection.state === HubConnectionState.Connected) {
                newConnection.stop();
                clearInterval(removeWorker)
                console.log("SignalR Disconnected");
            }
        };
    }, []);

    useEffect(() => {
        getGeoFences();
    }, []);

    return (
        <div>
            <Modal
                isOpen={open}
                onRequestClose={() => {setOpen(false)}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className='modal-live'
                style={{
                    overlay: {
                        zIndex: 10000,
                        backgroundColor: 'transparent',
                        transition: 'none',
                        width: '100%',
                        msTransformOrigin: 'right'
                    }
                }}
            >
                <div className="modal-header">
                    <h3>Tracking:</h3>
                    <button onClick={closeModal} className="close-modal-btn">&times;</button>
                </div>
                <div className="modal-body">
                    {workers.map((worker: any) => (
                        <div
                            key={worker.id}
                            className="worker-details"
                            onClick={() => getWorkerCoordinates(worker.latitude, worker.longitude)}
                        >
                            <p><strong>Name:</strong> {worker.name}</p>
                            <p><strong>PTS:</strong> {worker.pts}</p>
                        </div>
                    ))}
                </div>
            </Modal>
            <MapContainer center={coordinates} zoom={9} style={{ height: '92vh', width: '100%', zIndex: 1 }}>
                <FeatureGroup>
                    <EditControl
                        position="topleft"
                        onCreated={(e) => {
                            setCreatedLayer(e);
                            setModalOpen(true);
                            setGeofenceName("");
                            setGeofenceColor("green");
                        }}
                        draw={{
                            polyline: false,
                            polygon: true,
                            rectangle: false,
                            circle: true,
                            marker: false,
                            circlemarker: false,
                        }}
                    />
                    {geofences && geofences.map((geofence: any) => (
                        <>
                            {geofence.type === 'polygon' &&
                                <Polygon
                                    positions={geofence.vertices}
                                    pathOptions={{ color: geofence.color}}
                                    eventHandlers={{
                                      click: () => handleGeofenceClick(geofence),
                                    }}>
                                    <Tooltip permanent={true} direction="top">
                                      <div className="geofence-identifier">{geofence.identifier}</div>
                                    </Tooltip>
                                </Polygon>
                            }
                            {geofence.type === 'circle' &&
                                <Circle
                                    center={[geofence.latitude, geofence.longitude]}
                                    radius={geofence.radius}
                                    pathOptions={{color: geofence.color}}
                                    eventHandlers={{
                                      click: () => handleGeofenceClick(geofence),
                                    }}
                                >
                                    <Tooltip permanent={true} direction="top">
                                      <div className="geofence-identifier">{geofence.identifier}</div>
                                    </Tooltip>
                                </Circle>
                            }
                        </>
                    ))}
                </FeatureGroup>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Button
                    className="open-button"
                    onClick={() => setOpen(true)}
                >
                    <FontAwesomeIcon icon={faUserGroup} color="#464646" />
                </Button>
                {workers.map((worker: any) => (
                    <Marker
                        key={worker.id}
                        icon={customDivIcon}
                        position={[worker.latitude, worker.longitude]}
                    >
                        <Tooltip permanent={true} direction="top">
                            <div style={{ backgroundColor: 'white', fontWeight: 600, textAlign: 'center'}}>{worker.name} <br/> {worker.pts}</div>
                        </Tooltip>
                    </Marker>
                ))}
                <MapController coordinates={coordinates} zoom={zoom}/>
            </MapContainer>
            <GeofenceModal
                open={modalOpen}
                setModalOpen={setModalOpen}
                geofence={selectedGeofence}
                geofenceName={geofenceName}
                setGeofenceName={setGeofenceName}
                geofenceColor={geofenceColor}
                setGeofenceColor={setGeofenceColor}
                onSave={handleSaveGeofence}
                onDelete={handleGeofenceDelete}
            />
        </div>
    );
}

export default LiveTracker;
