import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../auth/auth";
import { getSignInOrOutLogs } from "../../api/apiCall";
import { format } from "date-fns";

const Signin: React.FC = () => {
    const { state } = useAuth();
    const [workers, setWorkers] = useState<any[]>([]);
    const [pagination, setPagination] = useState<number>(1);

    const fetchWorkers = async (page: number) => {
        try {
            const response = await getSignInOrOutLogs(state.user?.token, page);
            if (response && Array.isArray(response)) {
                setWorkers(response);
            } else {
                setWorkers([]);
                if (page !== 1) {
                    setPagination(1);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePageChange = (page: number) => {
        if (page > 0) {
            setPagination(page);
        }
    };

    useEffect(() => {
        fetchWorkers(pagination);
    }, [pagination]);

    return (
        <div className="location-container">
            <header className="header-logger">
                <h1>Sign In
                    <h4 className="uk-time">UK TIME ZONE</h4>
                </h1>
                {/* <TextField
                    label="Search..."
                    type="text"
                    size="small"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "20%", margin: 10 }}
                /> */}
            </header>

            <table className="location-logger-table">
                <thead>
                    <tr>
                        <th className="left-align">Team Name</th>
                        <th className="center">Full Name</th>
                        <th className="center">PTS</th>
                        <th className="center">Work Role</th>
                        <th className="center">Supervisor</th>
                        <th className="center">Date Time</th>
                        <th className="center">Sign In/Out</th>
                        <th className="left-align">Worker Signature</th>
                    </tr>
                </thead>
                <tbody>
                    {workers.length > 0 ? (
                        workers.map((worker: any) => (
                            <tr key={worker.id}>
                                <td style={{textAlign: "left"}}>{worker.whatsAppName}</td>
                                <td>{worker.workerName}</td>
                                <td>{worker.workerPTS}</td>
                                <td>{worker.workRole}</td>
                                <td>{worker.supervisorName}</td>
                                <td>
                                    {format(new Date(worker.createdDate), "do MMM, HH:mm")}
                                </td>
                                <td>{worker.signActionType === 0 ? 'Sign In' : 'Sign Out'}</td>
                                <img
                                    style={{ width: 100, height: 40, objectFit: "contain" }}
                                    src={"data:image/png;base64," + worker.signature }
                                />
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7} className="table-data center-align">
                                No workers found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className="pagination-div">
                <Button onClick={() => handlePageChange(pagination - 1)} disabled={pagination === 1}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <h3 style={{ margin: "0 10px" }}>{pagination}</h3>
                <Button onClick={() => handlePageChange(pagination + 1)} disabled={workers.length < 40}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
        </div>
    );
};

export default Signin;