/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faEnvelope,
    faEllipsis,
    faThumbsUp,
    faThumbsDown,
    faX
} from '@fortawesome/free-solid-svg-icons';
import { convertFromRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import './Shift.css';

type Props = {
    gridData: any
    colIndex: number
    rowIndex: number
    day: any
    flippedCells: any
}

const Shift = ({gridData, colIndex, rowIndex, day, flippedCells}: Props) => {
    const [shiftStatus, setShiftStatus] = useState<any>('')

    const getBackgroundColor = (shiftStatus: number) => {
        switch(shiftStatus){
            case 0:
              return '#F7DE3A';
            case 1:
              return '#74c365';
            case 2:
              return '#FF0000'
        }
    };

    const getShiftStatus = (shiftStatus: number) => {
        switch(shiftStatus) {
            case 0: 
              return 'Pending'
            case 1:
              return 'Sent'
            case 2:
              return 'Canceled'
        }
    }

    const getHotelStatus = (hotelStatus: number) => {
        switch(hotelStatus) {
            case 0: 
              return 'No Hotel Required'
            case 1:
              return 'Pending'
            case 2:
              return 'Booked'
        }
    }

    const getTStatus = (tStatus: number) => {
        switch(tStatus) {
            case 0: 
              return ''
            case 1:
              return <FontAwesomeIcon icon={faThumbsUp} />
            case 2:
              return <FontAwesomeIcon icon={faThumbsDown}/>
        }
    }

    const getTStatusColor = (tStatus: number) => {
        switch(tStatus) {
            case 0: 
              return 'white'
            case 1:
              return '#74c365'
            case 2:
              return 'red'
        }
    }

    const getSStatus = (sStatus: number) => {
        switch(sStatus) {
            case 0: 
              return ''
            case 1:
              return <FontAwesomeIcon icon={faCheck} />
            case 2:
              return <FontAwesomeIcon icon={faX}/>
            case 3:
              return <FontAwesomeIcon icon={faEnvelope}/>
        }
    }

    const getSStatusColor = (sStatus: number) => {
        switch(sStatus) {
            case 0:
              return 'white';
            case 1:
              return '#74c365';
            case 2:
              return 'red';
            case 3:
              return '#f7de3a'
        }
    }

    const getHStatus = (hStatus: number) => {
        switch(hStatus) {
            case 0: 
              return 'white'
            case 1:
              return '#ffc0cb'
            case 2:
              return '#90ee90'
            case 3:
              return '#FF0000'
        }
    }

    useEffect(() => {
        setShiftStatus(getShiftStatus(gridData[`${colIndex}-${rowIndex}`].shiftStatus))
    })

    const shiftStyle = {
        backgroundColor: getBackgroundColor(gridData[`${colIndex}-${rowIndex}`].shiftStatus)
    };

    const getTypeofWork = (typeofWork: any) => {
        if(typeofWork) {
            if(typeofWork === "Price Work" || typeofWork === "X Other" || typeofWork === "Labour") {
                const parts = typeofWork.split(" ");
                const firstLetter = parts[0][0];
                const secondLetter = parts[1] ? parts[1][0] : "";
                return typeofWork === "X Other" ? ` (X)` : ` (${firstLetter}${secondLetter})`;
            }
        }
    };

    const renderSavedContent = () => {
        try {
            const contentState = convertFromRaw(JSON.parse(gridData[`${colIndex}-${rowIndex}`].notesJson));
            const editorState = EditorState.createWithContent(contentState);
    
            const customStyleMap = {
                inlineStyles: {
                    HIGHLIGHT: {
                        style: { backgroundColor: 'yellow' }
                    }
                }
            };

            let html = stateToHTML(editorState.getCurrentContent(), customStyleMap);
            html = html.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>');

            return <div dangerouslySetInnerHTML={{ __html: html }} />;
        } catch (error) {
            return <div></div>;
        }
    };

    return (
        <>
            <div className="flip-container" key={gridData[`${colIndex}-${rowIndex}`].id}>
                <div className="flip-shift-front">
                    <div className="shift-details-table" style={shiftStyle}>
                        <div className="details-data" style={{textAlign: 'right'}}>TEAM NAME:</div>
                        <div className="details-data centered">
                            {gridData[`${colIndex}-${rowIndex}`].wp}
                        </div>
                        <div className="details-data" style={{ textAlign: 'right' }}>
                            CLIENT & PROJECT:
                        </div>
                        <div className="details-data centered">
                            {gridData[`${colIndex}-${rowIndex}`].client} - {gridData[`${colIndex}-${rowIndex}`].project}
                            {getTypeofWork(gridData[`${colIndex}-${rowIndex}`].region)}
                        </div>
                        <div className="details-data" style={{textAlign: 'right'}}>CLIENT SITE CONTACT:</div>
                        <div className="details-data centered">
                            {gridData[`${colIndex}-${rowIndex}`].clientSiteContacts}
                        </div>
                        <div className="details-data" style={{textAlign: 'right', borderBottom: 0}}>PACE ON CALL:</div>
                        <div className="details-data centered" style={{borderBottom: 0}}>
                            {gridData[`${colIndex}-${rowIndex}`].onCallNumber}
                        </div>
                        
                    </div>
                    <div className="time-table">
                        <div className="time-table-header">START TIME</div>
                        <div className="time-table-header">FINISH TIME</div>
                        <div className="time-table-header">HOURS</div>
                        <div className="time-table-header" style={{fontSize: '15px'}}>JOB NUMBER</div>
                        <div className="time-table-header" style={{fontSize: '15px'}}>LABOUR ORDER NR</div>
                        <div className="time-table-header">INVOICE</div>
                        <div className="time-table-data" style={shiftStyle}>
                            {gridData[`${colIndex}-${rowIndex}`].startTime}
                        </div>
                        <div className="time-table-data" style={shiftStyle}>
                            {gridData[`${colIndex}-${rowIndex}`].endTime}
                        </div>
                        <div className="time-table-data" style={shiftStyle}>
                            {gridData[`${colIndex}-${rowIndex}`].hours}
                        </div>
                        <div className="time-table-data" style={{...shiftStyle, fontSize: 13}}>
                            {gridData[`${colIndex}-${rowIndex}`].jobNumber}
                        </div>
                        <div className="time-table-data" style={shiftStyle}>
                            {gridData[`${colIndex}-${rowIndex}`].labourOrderNR}
                        </div>
                        <div className="time-table-data" style={shiftStyle}>
                            {gridData[`${colIndex}-${rowIndex}`].invoice}
                        </div>
                    </div>
                    <div className="worker-table">
                        <div className="worker-header">ROLE</div>
                        <div className="worker-header">FULL NAME</div>
                        <div className="worker-header">PTS</div>
                        <div className="worker-header">Number</div>
                        <div className="worker-header">T</div>
                        <div className="worker-header">S</div>
                        <div className="worker-header">H</div>
                        <div className="worker-header">F</div>
                        <div className="worker-header">D</div>
                        <div className="worker-header">P</div>
                        <div className="worker-header">NOTES</div>
                        {gridData[`${colIndex}-${rowIndex}`].workers.map((worker: any) => (
                            <>
                                <div
                                    className={`worker-data ${worker.paid && 'worker-paid'}`}
                                    style={{backgroundColor: worker.paid ? 'lightgreen' : worker.colorIdentifier ? worker.colorIdentifier: '', textAlign: 'left',
                                        color: worker.h_Color === 3 ?  'red': 'black',fontWeight: worker.h_Color === 3 ?  600 : 400
                                    }}
                                >
                                    {worker.workRole}
                                </div>
                                <div className="worker-data" style={{backgroundColor: worker.colorIdentifier, textAlign: 'left',color: worker.h_Color === 3 ?  'red': 'black', fontWeight: worker.h_Color === 3 ?  600 : 400}}>{worker.fullName}</div>
                                <div className="worker-data" style={{backgroundColor: worker.colorIdentifier, color: worker.h_Color === 3 ?  'red': 'black', fontWeight: worker.h_Color === 3 ?  600 : 400}}>{worker.pts}</div>
                                <div className="worker-data" style={{backgroundColor: worker.colorIdentifier, color: worker.h_Color === 3 ?  'red': 'black', fontWeight: worker.h_Color === 3 ?  600 : 400}}>
                                    
                                    {worker.phoneNumber? (
                                        <>
                                            <div className="show-postcode">
                                                <div className="postcode-hover">
                                                    {worker.phoneNumber}
                                                </div>
                                                {worker.postCode ? (
                                                    <div className="postcode-shown">
                                                        {worker.postCode}
                                                    </div>
                                                ) :
                                                    <div className="postcode-shown">
                                                        No Post Code
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    ):(null)}
                                </div>
                                <div className="worker-data" style={{backgroundColor: getTStatusColor(worker.t)}}>
                                    {getTStatus(worker.t)}
                                </div>
                                <div className="worker-data" style={{backgroundColor: getSStatusColor(worker.s)}}>
                                    {getSStatus(worker.s)}
                                </div>
                                <div className="worker-data" style={{backgroundColor: getHStatus(worker.h_Color)}}></div>
                                <div className="worker-data" style={{backgroundColor: worker.f? '#74c365': 'white'}}>
                                    {worker.f? (
                                        <FontAwesomeIcon icon={faCheck}/>
                                    ): null}
                                </div>
                                <div className="worker-data" style={{backgroundColor: worker.d? '#87cefa': 'white'}}>
                                    {worker.d? (
                                        <FontAwesomeIcon icon={faCheck}/>
                                    ): null}
                                </div>
                                <div className="worker-data">
                                    {worker.paymentMethod?.displayName}
                                </div>
                                <div className="worker-data">
                                    {worker.notes? (
                                        <>
                                            <div className="show-notes">
                                                <div className="notes-hover">
                                                <FontAwesomeIcon icon={faEllipsis}/>
                                                </div>
                                                <div className="notes-shown">
                                                  {worker.notes}
                                                </div>
                                            </div>
                                        </>
                                    ):(null)}
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="location-table">
                        <div className="location-header" style={{ fontWeight: 600, ...shiftStyle, borderLeft: '0.5px solid black', textAlign: 'right' }}>
                            Location:
                        </div>
                        <div className="location-header" style={{...shiftStyle, fontWeight: 600}}>
                            {gridData[`${colIndex}-${rowIndex}`].location}
                        </div>
                        <div className="location-header" style={{...shiftStyle, textAlign: 'right'}}>
                            <div style={{fontWeight: 600}}> POST CODE:</div>
                        </div>
                        <div className="location-header" style={{fontWeight: 600, ...shiftStyle }}>
                            {gridData[`${colIndex}-${rowIndex}`].postCode}
                        </div>
                    </div>
                    <div className="info-table">
                        <div className="info-header">
                            <div style={{fontWeight: 600, textAlign: 'right'}}>Job:</div>
                        </div>
                        <div className="info-data" style={{ display: 'flex', flexDirection: 'column' }}>
                            {gridData[`${colIndex}-${rowIndex}`].description}
                            <br/>
                            <div style={{color: "red", fontWeight: 600}}>{gridData[`${colIndex}-${rowIndex}`].additionalInformation}</div>
                        </div>
                        <div className="info-header">
                            <div style={{fontWeight: 600, textAlign: 'right'}}>Vehicles/Plant:</div>
                        </div>
                        <div className="info-data" style={{ display: 'flex', flexDirection: 'column' }}>
                            {gridData[`${colIndex}-${rowIndex}`].plants_Materials}
                        </div>
                    </div>
                    {gridData[`${colIndex}-${rowIndex}`].hotelStatus === 0 ?
                        <div className="notes-table">
                            <div className="notes-header" style={{backgroundColor: '#bfbfbf'}}>
                                <div style={{fontWeight: 600, textAlign: 'center'}}>PERSONAL NOTES SECTION (PACE ONLY, DON'T SEND)</div>
                            </div>
                            <div className="notes-data">
                                {renderSavedContent()}
                            </div>
                        </div> :
                        <div className="notes-hotel-table">
                            <div className="notes-header" style={{backgroundColor: '#bfbfbf'}}>
                                <div style={{fontWeight: 600, textAlign: 'center'}}>PERSONAL NOTES SECTION (PACE ONLY, DON'T SEND)</div>
                            </div>
                            <div className="notes-header" style={{backgroundColor: '#ffc000'}}>
                                <div style={{fontWeight: 600, textAlign: 'center'}}>HOTELS</div>
                            </div>
                            <div className="notes-data">
                                {renderSavedContent()}
                            </div>
                            <div className="notes-data" style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', fontWeight: 500 }}>
                                {getHotelStatus(gridData[`${colIndex}-${rowIndex}`].hotelStatus)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Shift;
